import React from "react";
import cloud from "./assets/Wordcloud.png";
import scottkutler from "./assets/scottkutler-new.png";
import WorkPanel from "./WorkPanel";

class About extends React.Component {
  render() {
    return (
      <div className="about-page">
        <div className="aboutme-content animate fade-in-from-bottom">
          <div className="aboutme-box">
            <img
              alt="Scott Kutler portrait"
              className="scottkutler animate fade-in-from-bottom"
              src={scottkutler}
            />
            <h4 className="resume-aboutme">
              <div className="c23 animate fade-in-from-bottom">
                Scott Kutler<br />
              </div>
              <div data-delay="500" className="c24 animate fade-in-from-bottom">
                Sr. Software Developer & Product Manager
              </div>
              <br />
              <p data-delay="750" className="animate fade-in-from-bottom"> I am an experienced start-up founder with a focus on software engineering and product management. With my optimistic personality and ability to problem-solve under pressure, I serve as a great addition to any team. I enjoy collaborative and supportive  environments with employees that care about and actively work towards positive social impact.</p>
            </h4>
          </div>
        </div>

        <div id="Experience">
          <br />
          <div className="WorkPanel-container animate fade-in-from-bottom">
            <h1 className="experience-header">Experience</h1>
            <WorkPanel />
            <div className="resume-flex-grid">
              <a href="https://docs.google.com/document/d/1COw4Wc7s6oVGetwuoHg8AE_GhIWLzwiV6-ZEw_SdIdc/edit?usp=sharing">
                <div className="FullResume">
                  <h5 className="resume-button">View Full Resume</h5>
                </div>
              </a>
            </div>
          </div>
          <div className="skills-spacer" id="skills"><a name="Skills" id="Skills"></a></div>
                
        </div>

        <h1 className="skill-header animate fade-in-from-bottom">Skills</h1>
        <div className="about-flex-grid animate fade-in-from-bottom">
          <div className="col1">
            <h4 className="about-h4-left">Front-End</h4>
            <h5 className="about-h5-left">
              <span className="skills-bullet">•</span> HTML <br />
              <span className="skills-bullet">•</span> CSS <br />
              <span className="skills-bullet">•</span> Javascript <br />
              <span className="skills-bullet">•</span> React <br />
              <span className="skills-bullet">•</span> Responsive Design <br />
              <span className="skills-bullet">•</span> jQuery
            </h5>
            <h4 className="about-h4-left">Deployment</h4>
            <h5 className="about-h5-left">
              <span className="skills-bullet">•</span> Git / Github <br />
              <span className="skills-bullet">•</span> Heroku <br />
              <span className="skills-bullet">•</span> Bitbucket <br />
              <span className="skills-bullet">•</span> Netlify <br />
              <span className="skills-bullet">•</span> Amazon AWS <br />
              <span className="skills-bullet">•</span> Firebase <br />
            </h5>
            <h4 className="about-h4-left">Design</h4>
            <h5 className="about-h5-left">
              <span className="skills-bullet">•</span> Final Cut Pro (Certified)
              <br />
              <span className="skills-bullet">•</span> Adobe Photoshop <br />
              <span className="skills-bullet">•</span> Figma
              <br />
              <span className="skills-bullet">•</span> Sketch
              <br />
            </h5>
          </div>
          <div className="col2 about-wordcloud animate fade-in-from-bottom">
            <img alt="Skills wordcloud" className="about-wordcloud" src={cloud} />
          </div>
          <div className="col3">
            <h4 className="about-h4-right">Backend</h4>
            <h5 className="about-h5-right">
              <span className="skills-bullet">•</span> Express.js <br />
              <span className="skills-bullet">•</span> Python <br />
              <span className="skills-bullet">•</span> MongoDB <br />
              <span className="skills-bullet">•</span> Python <br />
              <span className="skills-bullet">•</span> RESTful API <br />
              <span className="skills-bullet">•</span> Django <br />
              <span className="skills-bullet">•</span> SQL <br />
              <span className="skills-bullet">•</span> PostgreSQL <br />
            </h5>
            <h4 className="about-h4-right">Project Management</h4>
            <h5 className="about-h5-right">
              <span className="skills-bullet">•</span> Rapid Prototyping <br />
              <span className="skills-bullet">•</span> Google Analytics <br />
              <span className="skills-bullet">•</span> Google Play <br />
              <span className="skills-bullet">•</span> App Store Connect <br />
              <span className="skills-bullet">•</span> Jira & Trello
            </h5>
            <h4 className="about-h4-right">Misc. Skills</h4>
            <h5 className="about-h5-right">
              <span className="skills-bullet">•</span> Unix <br />
              <span className="skills-bullet">•</span> VS Code<br />
              <span className="skills-bullet">•</span> MS Office (Certified) <br />
              <span className="skills-bullet">•</span> Wordpress CMS <br />
              <span className="skills-bullet">•</span> Webflow CMS <br />
              <span className="skills-bullet">•</span> Drupal CMS
              <span className="skills-bullet">•</span> Social Media
            </h5>
          </div>
        </div>

        <div id="Portfolio"></div>
      </div>
    );
  }
}

export default About;
