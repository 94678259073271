import React from "react";

class Intro extends React.Component {
  render() {
    return (
      <div className="intro-background-color fade-in-fast">
        <div className="intro-overlay" />
        <div className="intro-background fade-in-fast">
          <h1 className="intro-name animate fade-in-from-bottom">Scott Kutler</h1>
          <div className="intro-subheading animate fade-in-from-bottom">Senior Developer</div>
        </div>
      </div>
    );
  }
}

export default Intro;
